import { Typography, Theme, Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, SymbolDisplay, GroupedField, IUpdatedFormPropertyStyler } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, TextDisplay, DateDisplay, BooleanDisplay, NumericDisplay } from '@ngt/forms';
import { ConditionMode } from '@ngt/forms-core';
import { makeStyles } from 'tss-react/mui';

import { Histopathology as HistopathologyDto } from '../../apis/dtos';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';

interface IHistopathologyProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    notDoneStyle: {
        padding: theme.spacing(2)
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const histopathologyOther: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.histopathologyType?.includes("Other") === true;
const tilNotDone: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.tilNotDone === true;
const tilDone: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.tilNotDone !== true;
const pdlDone: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.pdlTestingCompleted === true;
const pdlHasResponse: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.pdlTestingCompleted !== undefined && value?.pdlTestingCompleted !== null;
const pdlHasNoResponse: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => value?.pdlTestingCompleted !== undefined || value?.pdlTestingCompleted !== null;
const pdlAssayOther: ICondition<HistopathologyDto> = ({ formState: { value } = {} }) => !!value?.pdlAssayTypeSpec;

const conditionSubscription = { value: true };


const oestrogenHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'oestrogenSymbol'
        }
    ]
};


const progesteroneHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'progesteroneSymbol'
        }
    ]
};


const tilCountHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'tilCountSymbol'
        }
    ]
};

const Histopathology: FunctionComponent<IHistopathologyProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedField
                    name="dateOfHistopathDignosis"
                    component={DateDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField
                    name="primaryTumourSite"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <Typography variant="h2" className={classes.subtitle}>
                    Clinical Staging
                </Typography>
                <GroupedField
                    name="clinicalStagingCt"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField
                    name="clinicalStagingCn"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField
                    name="clinicalStagingCm"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField
                    name="histopathologyType"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <Condition
                    type={HistopathologyDto}
                    condition={histopathologyOther}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="histopathologyTypeSpec"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </Condition>
                <GroupedField
                    name="stageAtDiagnosis"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <Typography variant="h2" className={classes.subtitle}>
                    Receptor Staining
                </Typography>
                <GroupedField
                    name="oestrogenValue"
                    component={SymbolDisplay}
                    symbolName="oestrogenSymbol"
                    suffix="%"
                    GroupStyleProps={groupStyleProps}
                    UpdatedFormPropertyStylerProps={oestrogenHighlighting}
                />
                <GroupedField
                    name="progesteroneValue"
                    component={SymbolDisplay}
                    symbolName="progesteroneSymbol"
                    suffix="%"
                    GroupStyleProps={groupStyleProps}
                    UpdatedFormPropertyStylerProps={progesteroneHighlighting}
                />
                <GroupedField
                    name="ihcScore"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField
                    name="ishScore"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <Condition
                    type={HistopathologyDto}
                    condition={tilNotDone}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="tilNotDone"
                        component={BooleanDisplay}
                        trueText="Not Done"
                        GroupStyleProps={groupStyleProps}
                    />
                </Condition>
                <Condition
                    type={HistopathologyDto}
                    condition={tilDone}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="tilCountValue"
                        component={SymbolDisplay}
                        symbolName="tilCountSymbol"
                        suffix="%"
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={tilCountHighlighting}
                    />
                </Condition>
                <Condition
                    type={HistopathologyDto}
                    condition={pdlHasNoResponse}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="pdlTestingCompleted"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                        convert={(value) => "Not Done"}
                    />
                </Condition>
                <Condition
                    type={HistopathologyDto}
                    condition={pdlHasResponse}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="pdlTestingCompleted"
                        component={BooleanDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </Condition>
                <Condition
                    type={HistopathologyDto}
                    condition={pdlDone}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="pdlAssayType"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Condition
                        type={HistopathologyDto}
                        condition={pdlAssayOther}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="pdlAssayTypeSpec"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <GroupedField
                        name="pdlResults"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="pdlResultsValue"
                        component={NumericDisplay}
                        suffix="%"
                        GroupStyleProps={groupStyleProps}
                    />
                </Condition>
                <GroupedField
                    name="brcaGermlineStatus"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Histopathology;
