import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Theme, Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay, FieldProvider, FormLabel } from '@ngt/forms';
import { CarboplatinTherapy, NeonTherapy as NeonTherapyDto, NivolumabTherapy, PaclitaxelTherapy } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { DateTime } from 'luxon';

import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

interface INeonTherapyProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    legend: {
        padding: theme.spacing(2),
        lineBreak: 'anywhere',
        '& span': {
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap'
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const hasNivolumab: ICondition<NeonTherapyDto> = ({ formState: { value } = {} }) => !!value?.nivolumabTherapies && value?.nivolumabTherapies?.length > 0;
const hasNoNivolumab: ICondition<NeonTherapyDto> = ({ formState: { value } = {} }) => !value?.nivolumabTherapies || value?.nivolumabTherapies?.length === 0;

const hasPaclitaxel: ICondition<NeonTherapyDto> = ({ formState: { value } = {} }) => !!value?.paclitaxelTherapies && value?.paclitaxelTherapies?.length > 0;
const hasNoPaclitaxel: ICondition<NeonTherapyDto> = ({ formState: { value } = {} }) => !value?.paclitaxelTherapies || value?.paclitaxelTherapies?.length === 0;

const hasCarboplatin: ICondition<NeonTherapyDto> = ({ formState: { value } = {} }) => !!value?.carboplatinTherapies && value?.carboplatinTherapies?.length > 0;
const hasNoCarboplatin: ICondition<NeonTherapyDto> = ({ formState: { value } = {} }) => !value?.carboplatinTherapies || value?.carboplatinTherapies?.length === 0;

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const notAdministeredAccessor = (value: string) => value ? <>Not Administered &ndash; {value}</> : 'Not Administered';
const booleanAccessor = (value: boolean) => value === true ? 'Yes' : value === false ? 'No' : null;

const nivolumabColumns: IGroupedSubformGridCell<NivolumabTherapy>[] = [
    {
        name: 'eventName',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'administered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'doseAdministered',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'expectedDose',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'doseDelayed',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="doseDelayedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'doseDelayedReason'
                }
            ]
        }
    },
    {
        name: 'lastDose',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="lastDoseSpecify"
                reasonName="lastDoseReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lastDoseSpecify'
                },
                {
                    name: 'lastDoseReason'
                }
            ]
        }
    }
];


const paclitaxelColumns: IGroupedSubformGridCell<PaclitaxelTherapy>[] = [
    {
        name: 'eventName',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'timepoint',
        width: 300,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'administered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'doseAdministered',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'reduced',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="reducedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'reducedReason'
                }
            ]
        }
    },
    {
        name: 'omitted',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="omittedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'omittedReason'
                }
            ]
        }
    },
    {
        name: 'lastDose',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="lastDoseSpecify"
                reasonName="lastDoseReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lastDoseSpecify'
                },
                {
                    name: 'lastDoseReason'
                }
            ]
        }
    }
];


const carboplatinColumns: IGroupedSubformGridCell<CarboplatinTherapy>[] = [
    {
        name: 'eventName',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'administered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'expectedDoseCalvert',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                decimalPlaces={2}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'doseAdministered',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'reduced',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="reducedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'reducedReason'
                }
            ]
        }
    },
    {
        name: 'doseDelayed',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="doseDelayedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'doseDelayedReason'
                }
            ]
        }
    },
    {
        name: 'lastDose',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="lastDoseSpecify"
                reasonName="lastDoseReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lastDoseSpecify'
                },
                {
                    name: 'lastDoseReason'
                }
            ]
        }
    }
];

const NeonTherapy: FunctionComponent<INeonTherapyProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureNivolumab">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureCarboplatin">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposurePaclitaxel">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureSurgery">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureNivolumab"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureCarboplatin"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposurePaclitaxel"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureSurgery"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className={classes.legend}>
                        <Typography color="textSecondary" component="span">
                            BCS = Breast Conserving Surgery;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            M = Mastectomy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            M+R = Mastectomy + Reconstruction;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            OS = Oncoplastic Surgery;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            SLNB = Sentinel Lymph Node Biopsy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            S-SM = Skin Sparing Mastectomy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            Oth = Other;
                        </Typography>
                    </div>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Nivolumab
                    </Typography>
                    <GroupedField
                        name="treatment"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="plannedStartDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Condition
                        type={NeonTherapyDto}
                        condition={hasNivolumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={NivolumabTherapy}
                            name="nivolumabTherapies"
                            columns={nivolumabColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={NeonTherapyDto}
                        condition={hasNoNivolumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Nivolumab Data"
                                description="There is no Nivolumab information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Paclitaxel
                    </Typography>
                    <GroupedField
                        name="expectedDoseMosteller"
                        component={NumericDisplay}
                        decimalPlaces={2}
                        GroupStyleProps={groupStyleProps}
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> mg/m<sup>2</sup></Typography>
                        }
                    />
                    <GroupedField
                        name="expectedDoseDubois"
                        component={NumericDisplay}
                        decimalPlaces={2}
                        GroupStyleProps={groupStyleProps}
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> mg/m<sup>2</sup></Typography>
                        }
                    />
                    <Condition
                        type={NeonTherapyDto}
                        condition={hasPaclitaxel}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={PaclitaxelTherapy}
                            name="paclitaxelTherapies"
                            columns={paclitaxelColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={NeonTherapyDto}
                        condition={hasNoPaclitaxel}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Paclitaxel Data"
                                description="There is no Paclitaxel information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Carboplatin
                    </Typography>
                    <Condition
                        type={NeonTherapyDto}
                        condition={hasCarboplatin}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={CarboplatinTherapy}
                            name="carboplatinTherapies"
                            columns={carboplatinColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={NeonTherapyDto}
                        condition={hasNoCarboplatin}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Carboplatin Data"
                                description="There is no Carboplatin information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default NeonTherapy;
