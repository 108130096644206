import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Theme, Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, InputOnlyField, GroupedField, IUpdatedFormPropertyStyler } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, DateDisplay, FieldProvider, FormLabel, BooleanDisplay, NumericDisplay } from '@ngt/forms';
import { Imaging as ImagingDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import CoalesceDisplay from '../../components/form/input/display/CoalesceDisplay';
import { DateTime } from 'luxon';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

interface IImagingProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const hasClinicalExam: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.clinicalExamNotDone !== true && !!value?.clinicalExamDate;
const noClinicalExam: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.clinicalExamNotDone === true;
const missingClinicalExam: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.clinicalExamNotDone !== true && !value?.clinicalExamDate;


const hasCytology: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.cytologyNotDone !== true && !!value?.cytologyDate;
const noCytology: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.cytologyNotDone === true;
const missingCytology: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.cytologyNotDone !== true && !value?.cytologyDate;

const hasLeftMammogram: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !!value?.mammogramLeftDate;
const hasRightMammogram: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !!value?.mammogramRightDate;
const hasBilateralMammogram: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !!value?.mammogramDate;
const missingMammogram: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.mammogramDate && !value?.mammogramRightDate && !value?.mammogramLeftDate;

const hasAbdomenUltrasound: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.abdomenUltrasoundNotDone !== true && value?.abdomenUltrasoundNotAvailable !== true && !!value?.abdomenUltrasoundDate;
const noAbdomenUltrasound: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.abdomenUltrasoundNotDone === true;
const naAbdomenUltrasound: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.abdomenUltrasoundNotDone !== true && value?.abdomenUltrasoundNotAvailable === true;
const missingAbdomenUltrasound: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.abdomenUltrasoundNotDone !== true && value?.abdomenUltrasoundNotAvailable !== true && !value?.abdomenUltrasoundDate;

const hasChestXray: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.chestXrayNotDone !== true && !!value?.chestXrayDate;
const noChestXray: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.chestXrayNotDone === true;
const missingChestXray: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.chestXrayNotDone !== true && !value?.chestXrayDate;


const hasBoneScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.boneScanNotDone !== true && !!value?.boneScanDate;
const noBoneScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.boneScanNotDone === true;
const missingBoneScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.boneScanNotDone !== true && !value?.boneScanDate;


const hasPetScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.petScanNotDone !== true && !!value?.petScanDate;
const noPetScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.petScanNotDone === true;
const missingPetScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.petScanNotDone !== true && !value?.petScanDate;


const hasCtScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.ctScanNotDone !== true && !!value?.ctScanDate;
const noCtScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.ctScanNotDone === true;
const missingCtScan: ICondition<ImagingDto> = ({ formState: { value } = {} }) => value?.ctScanNotDone !== true && !value?.ctScanDate;

const conditionSubscription = { value: true };

const dataAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const boolAccessor = (value: boolean) => value === true ? 'Not Done' : null;


const axillaUltrasoundClipHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'axillaUltrasoundClipDate'
        }
    ]
};

const breastUltrasoundClipHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'breastUltrasoundClipDate'
        }
    ]
};

const Imaging: FunctionComponent<IImagingProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Breast
                    </Typography>
                    <Typography variant="h3" className={classes.subtitle}>
                        Ultrasound
                    </Typography>
                    <GroupedField
                        name="breastUltrasoundDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="breastUltrasoundClipNotDone"
                        component={CoalesceDisplay}
                        ifNullName="breastUltrasoundClipDate"
                        ifNullAccessor={dataAccessor}
                        accessor={boolAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={breastUltrasoundClipHighlighting}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="breastUltrasoundLesionSideId">
                                        <FormLabel  />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="breastUltrasoundLesionLocationId">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="breastUltrasoundLesionDimensionOne">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="breastUltrasoundLesionDimensionTwo">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <InputOnlyField
                                        name="breastUltrasoundLesionSideId"
                                        component={TextDisplay}
                                    /> 
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="breastUltrasoundLesionLocationId"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="breastUltrasoundLesionDimensionOne"
                                        component={NumericDisplay}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="breastUltrasoundLesionDimensionTwo"
                                        component={NumericDisplay}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Axilla
                    </Typography>
                    <Typography variant="h3" className={classes.subtitle}>
                        Ultrasound
                    </Typography>
                    <GroupedField
                        name="axillaUltrasoundDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="axillaUltrasoundSuspiciousNodes"
                        component={NumericDisplay}
                        GroupStyleProps={groupStyleProps}
                        suffix={<Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>}
                    />
                    <GroupedField
                        name="axillaUltrasoundClipNotDone"
                        component={CoalesceDisplay}
                        ifNullName="axillaUltrasoundClipDate"
                        ifNullAccessor={dataAccessor}
                        accessor={boolAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={axillaUltrasoundClipHighlighting}
                    />
                    <Typography variant="h3" className={classes.subtitle}>
                        Clinical Examination
                    </Typography>
                    <Condition
                        type={ImagingDto}
                        condition={hasClinicalExam}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="clinicalExamDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="clinicalExamSuspiciousNodes"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={noClinicalExam}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="clinicalExamNotDone"
                            component={BooleanDisplay}
                            trueText="Not Done"
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={missingClinicalExam}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Clinical Exam Data"
                                description="There is no clinical exam information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        Cytological Assessment
                    </Typography>
                    <Condition
                        type={ImagingDto}
                        condition={hasCytology}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="cytologyDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="cytologyResults"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={noCytology}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="cytologyNotDone"
                            component={BooleanDisplay}
                            trueText="Not Done"
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={missingCytology}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Cytological Assessment Data"
                                description="There is no cytological assessment information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Mammogram
                    </Typography>
                    <Condition
                        type={ImagingDto}
                        condition={hasLeftMammogram}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="mammogramLeftDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={hasRightMammogram}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="mammogramRightDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={hasBilateralMammogram}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="mammogramDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={missingMammogram}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Mammogram Data"
                                description="There is no mammogram information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Other
                    </Typography>
                    <Typography variant="h3" className={classes.subtitle}>
                        Abdomen Ultrasound
                    </Typography>
                    <Condition
                        type={ImagingDto}
                        condition={hasAbdomenUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="abdomenUltrasoundDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="abdomenUltrasoundFinding"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={noAbdomenUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="abdomenUltrasoundNotDone"
                            component={BooleanDisplay}
                            trueText="Not Done"
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={naAbdomenUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="abdomenUltrasoundNotAvailable"
                            component={BooleanDisplay}
                            trueText="NA"
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={missingAbdomenUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Abdomen Ultrasound Data"
                                description="There is no abdomen ultrasound information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        Chest X-ray
                    </Typography>
                    <Condition
                        type={ImagingDto}
                        condition={hasChestXray}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="chestXrayDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="chestXrayFindings"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={noChestXray}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="chestXrayNotDone"
                            component={BooleanDisplay}
                            trueText="Not Done"
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={missingChestXray}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Chest X-ray Data"
                                description="There is no chest X-ray information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        CT Scan
                    </Typography>
                    <Condition
                        type={ImagingDto}
                        condition={hasCtScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="ctScanDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="ctScanFindings"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={noCtScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="ctScanNotDone"
                            component={BooleanDisplay}
                            trueText="Not Done"
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={missingCtScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No CT Scan Data"
                                description="There is no CT scan information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        PET Scan
                    </Typography>
                    <Condition
                        type={ImagingDto}
                        condition={hasPetScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="petScanDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="petScanFindings"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={noPetScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="petScanNotDone"
                            component={BooleanDisplay}
                            trueText="Not Done"
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={missingPetScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No PET Scan Data"
                                description="There is no PET scan information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        Bone Scan
                    </Typography>
                    <Condition
                        type={ImagingDto}
                        condition={hasBoneScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="boneScanDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="boneScanFindings"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={noBoneScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="boneScanNotDone"
                            component={BooleanDisplay}
                            trueText="Not Done"
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={ImagingDto}
                        condition={missingBoneScan}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Bone Scan Data"
                                description="There is no bone scan information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Imaging;
