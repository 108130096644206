import { Box, Typography, Theme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, OpenClinicaFormDisplay, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-medical-review';
import { GroupedFields, Input, TextDisplay, DateDisplay, BooleanDisplay, FieldProvider } from '@ngt/forms';
import { ConcomitantMedicationItem, ConcomitantMedications as ConcomitantMedicationsDto, MedicalHistoryMedication } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';

import TableTextResizer from '../../components/form/TableTextResizer';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IConcomitantMedicationsProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    }
}));

const registrationConcomitantMedicationColumns: IGroupedSubformGridCell<MedicalHistoryMedication>[] = [
    {
        name: 'medicationName',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.medicationName?.toLowerCase()
    },
    {
        name: 'indication',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.indication
    },
    {
        name: 'startDate',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.startDate
    }
];

const onStudyConcomitantMedicationColumns: IGroupedSubformGridCell<ConcomitantMedicationItem>[] = [
    {
        name: 'medication',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.medication?.toLowerCase()
    },
    {
        name: 'indication',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.indication
    },
    {
        name: 'dateStarted',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateStarted
    },
    {
        name: 'dateStopped',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateStopped
    },
    {
        name: 'ongoing',
        content: (
            <Input
                component={BooleanDisplay}
            />
        ),
        sortValue: (row) => row?.ongoing
    },
    {
        name: 'annotations',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.annotations
    },
];


const hasNoRegistrationConcomitantMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !value?.registrationConcomitantMedicationItems || value?.registrationConcomitantMedicationItems?.length === 0;
const hasRegistrationConcomitantMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !!value?.registrationConcomitantMedicationItems && value?.registrationConcomitantMedicationItems?.length > 0;
const hasNoOnStudyConcomitantMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !value?.onStudyConcomitantMedicationItems || value?.onStudyConcomitantMedicationItems?.length === 0;
const hasOnStudyConcomitantMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !!value?.onStudyConcomitantMedicationItems && value?.onStudyConcomitantMedicationItems?.length > 0;

const conditionSubscription = { value: true };

const ConcomitantMedications: FunctionComponent<IConcomitantMedicationsProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Medical History
                    </Typography>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasRegistrationConcomitantMedications}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box className={classes.table}>
                            <GroupedSubformGrid
                                type={MedicalHistoryMedication}
                                name="registrationConcomitantMedicationItems"
                                columns={registrationConcomitantMedicationColumns}
                                disabled
                                stickHeader
                            />
                        </Box>
                    </Condition>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasNoRegistrationConcomitantMedications}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Medical History Concomitant Medications"
                                description="There is no Medical History Concomitant Medication associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Box
                        sx={{ display: 'flex', gap: '1rem' }}
                    >
                        <Typography variant="h2" className={classes.subtitle}>
                            On Study
                        </Typography>
                        <FieldProvider
                            name="event"
                        >
                            <Box sx={{ marginTop: '0.75rem' }} >
                                <Input
                                    component={OpenClinicaFormDisplay}
                                    openClinicaUrl={openClinicaUrl}
                                    eventCrfIdName="eventCrfId"
                                    studyEventIdName="studyEventId"
                                    btnMinWidth="140px"
                                    eventCrfIdPrependParentName={false}
                                    studyEventIdPrependParentName={false}
                                    textValueButton
                                />
                            </Box>
                        </FieldProvider>
                    </Box>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasOnStudyConcomitantMedications}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box className={classes.table}>
                            <GroupedSubformGrid
                                type={ConcomitantMedicationItem}
                                name="onStudyConcomitantMedicationItems"
                                columns={onStudyConcomitantMedicationColumns}
                                disabled
                                stickHeader
                            />
                        </Box>
                    </Condition>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasNoOnStudyConcomitantMedications}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No On Study Concomitant Medications"
                                description="There is no On Study Concomitant Medication associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default ConcomitantMedications;
