import { Typography, Theme, Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, OpenClinicaFormDisplay, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-medical-review';
import { GroupedFields, Input, TextDisplay } from '@ngt/forms';
import { Chemistry, Coagulation, Haematology, OrganFunctionTreatment as OrganFunctionTreatmentDto, ThyroidFunction } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { DateTime } from 'luxon';

import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import BiochemistryDisplay from '../../components/form/input/display/BiochemistryDisplay';
import ValueWithIfElseDisplay from '../../components/form/input/display/ValueWithIfElseDisplay';
import FormDates from '../../components/form/FormDates';

interface IOrganFunctionTreatmentProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const conditionSubscription = { value: true };

const hasHaematology: ICondition<OrganFunctionTreatmentDto> = ({ formState: { value } = {} }) => !!value?.haematology && value?.haematology?.length > 0;
const hasNoHaematology: ICondition<OrganFunctionTreatmentDto> = ({ formState: { value } = {} }) => !value?.haematology || value?.haematology?.length === 0;

const hasChemistry: ICondition<OrganFunctionTreatmentDto> = ({ formState: { value } = {} }) => !!value?.chemistry && value?.chemistry?.length > 0;
const hasNoChemistry: ICondition<OrganFunctionTreatmentDto> = ({ formState: { value } = {} }) => !value?.chemistry || value?.chemistry?.length === 0;

const hasThyroidFunction: ICondition<OrganFunctionTreatmentDto> = ({ formState: { value } = {} }) => !!value?.thyroidFunction && value?.thyroidFunction?.length > 0;
const hasNoThyroidFunction: ICondition<OrganFunctionTreatmentDto> = ({ formState: { value } = {} }) => !value?.thyroidFunction || value?.thyroidFunction?.length === 0;

const hasCoagulation: ICondition<OrganFunctionTreatmentDto> = ({ formState: { value } = {} }) => !!value?.coagulation && value?.coagulation?.length > 0;
const hasNoCoagulation: ICondition<OrganFunctionTreatmentDto> = ({ formState: { value } = {} }) => !value?.coagulation || value?.coagulation?.length === 0;

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const notAdministeredAccessor = (value: string) => value ? <>Not Assessed &ndash; {value}</> : 'Not Assessed';

const performedAccessor = (value: string) => value === "Performed" ? undefined : value;

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

const haematologyColumns: IGroupedSubformGridCell<Haematology>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'timepoint',
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'assessmentPerformed',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'notAdministeredReason'
                },
                {
                    name: 'date'
                }
            ]
        }
    },
    {
        name: 'wbcCount',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="wbcCountLln"
                notDoneName="wbcCountNotDone"
                signName="wbcCountSign"
                prependParentName
                ulnName="wbcCountUln"
                tableCell
                unitName="wbcCountUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'wbcCountLln'
                },
                {
                    name: 'wbcCountNotDone'
                },
                {
                    name: 'wbcCountSign'
                },
                {
                    name: 'wbcCountUln'
                },
                {
                    name: 'wbcCountUnits'
                }
            ]
        }
    },
    {
        name: 'haemoglobin',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="haemoglobinLln"
                notDoneName="haemoglobinNotDone"
                signName="haemoglobinSign"
                prependParentName
                ulnName="haemoglobinUln"
                tableCell
                unitName="haemoglobinUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'haemoglobinLln'
                },
                {
                    name: 'haemoglobinNotDone'
                },
                {
                    name: 'haemoglobinSign'
                },
                {
                    name: 'haemoglobinUln'
                },
                {
                    name: 'haemoglobinUnits'
                }
            ]
        }
    },
    {
        name: 'platelets',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="plateletsLln"
                notDoneName="plateletsNotDone"
                signName="plateletsSign"
                prependParentName
                ulnName="plateletsUln"
                tableCell
                unitName="plateletsUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'plateletsLln'
                },
                {
                    name: 'plateletsNotDone'
                },
                {
                    name: 'plateletsSign'
                },
                {
                    name: 'plateletsUln'
                },
                {
                    name: 'plateletsUnits'
                }
            ]
        }
    },
    {
        name: 'rbcCount',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="rbcCountLln"
                notDoneName="rbcCountNotDone"
                signName="rbcCountSign"
                prependParentName
                ulnName="rbcCountUln"
                tableCell
                unitName="rbcCountUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: ''
                },
                {
                    name: ''
                },
                {
                    name: ''
                },
                {
                    name: ''
                },
                {
                    name: ''
                }
            ]
        }
    },
    {
        name: 'totalNeutrophils',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="totalNeutrophilsLln"
                notDoneName="totalNeutrophilsNotDone"
                signName="totalNeutrophilsSign"
                prependParentName
                ulnName="totalNeutrophilsUln"
                tableCell
                unitName="totalNeutrophilsUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'totalNeutrophilsLln'
                },
                {
                    name: 'totalNeutrophilsNotDone'
                },
                {
                    name: 'totalNeutrophilsSign'
                },
                {
                    name: 'totalNeutrophilsUln'
                },
                {
                    name: 'totalNeutrophilsUnits'
                }
            ]
        }
    },
    {
        name: 'lymphocytes',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="lymphocytesLln"
                notDoneName="lymphocytesNotDone"
                signName="lymphocytesSign"
                prependParentName
                ulnName="lymphocytesUln"
                tableCell
                unitName="lymphocytesUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lymphocytesLln'
                },
                {
                    name: 'lymphocytesNotDone'
                },
                {
                    name: 'lymphocytesSign'
                },
                {
                    name: 'lymphocytesUln'
                },
                {
                    name: 'lymphocytesUnits'
                }
            ]
        }
    }
];

const chemistryOneColumns: IGroupedSubformGridCell<Chemistry>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'assessmentPerformed',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'alanineTransaminase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="alanineTransaminaseLln"
                notDoneName="alanineTransaminaseNotDone"
                signName="alanineTransaminaseSign"
                prependParentName
                ulnName="alanineTransaminaseUln"
                tableCell
                unitName="alanineTransaminaseUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'alanineTransaminaseLln'
                },
                {
                    name: 'alanineTransaminaseNotDone'
                },
                {
                    name: 'alanineTransaminaseSign'
                },
                {
                    name: 'alanineTransaminaseUln'
                },
                {
                    name: 'alanineTransaminaseUnits'
                }
            ]
        }
    },
    {
        name: 'alkalinePhosphatase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="alkalinePhosphataseLln"
                notDoneName="alkalinePhosphataseNotDone"
                signName="alkalinePhosphataseSign"
                prependParentName
                ulnName="alkalinePhosphataseUln"
                tableCell
                unitName="alkalinePhosphataseUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'alkalinePhosphataseLln'
                },
                {
                    name: 'alkalinePhosphataseNotDone'
                },
                {
                    name: 'alkalinePhosphataseSign'
                },
                {
                    name: 'alkalinePhosphataseUln'
                },
                {
                    name: 'alkalinePhosphataseUnits'
                }
            ]
        }
    },
    {
        name: 'aspartateTransaminase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="aspartateTransaminaseLln"
                notDoneName="aspartateTransaminaseNotDone"
                signName="aspartateTransaminaseSign"
                prependParentName
                ulnName="aspartateTransaminaseUln"
                tableCell
                unitName="aspartateTransaminaseUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'aspartateTransaminaseLln'
                },
                {
                    name: 'aspartateTransaminaseNotDone'
                },
                {
                    name: 'aspartateTransaminaseSign'
                },
                {
                    name: 'aspartateTransaminaseUln'
                },
                {
                    name: 'aspartateTransaminaseUnits'
                }
            ]
        }
    },
    {
        name: 'amylase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="amylaseLln"
                notDoneName="amylaseNotDone"
                signName="amylaseSign"
                prependParentName
                ulnName="amylaseUln"
                tableCell
                unitName="amylaseUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'amylaseLln'
                },
                {
                    name: 'amylaseNotDone'
                },
                {
                    name: 'amylaseSign'
                },
                {
                    name: 'amylaseUln'
                },
                {
                    name: 'amylaseUnits'
                }
            ]
        }
    },
    {
        name: 'bloodGlucose',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="bloodGlucoseLln"
                notDoneName="bloodGlucoseNotDone"
                signName="bloodGlucoseSign"
                prependParentName
                ulnName="bloodGlucoseUln"
                tableCell
                unitName="bloodGlucoseUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'bloodGlucoseLln'
                },
                {
                    name: 'bloodGlucoseNotDone'
                },
                {
                    name: 'bloodGlucoseSign'
                },
                {
                    name: 'bloodGlucoseUln'
                },
                {
                    name: 'bloodGlucoseUnits'
                }
            ]
        }
    },
    {
        name: 'calcium',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="calciumLln"
                notDoneName="calciumNotDone"
                signName="calciumSign"
                prependParentName
                ulnName="calciumUln"
                tableCell
                unitName="calciumUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'calciumLln'
                },
                {
                    name: 'calciumNotDone'
                },
                {
                    name: 'calciumSign'
                },
                {
                    name: 'calciumUln'
                },
                {
                    name: 'calciumUnits'
                }
            ]
        }
    }
];

const chemistryTwoColumns: IGroupedSubformGridCell<Chemistry>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'assessmentPerformed',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'cortisol',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="cortisolLln"
                notDoneName="cortisolNotDone"
                signName="cortisolSign"
                prependParentName
                ulnName="cortisolUln"
                tableCell
                unitName="cortisolUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'cortisolLln'
                },
                {
                    name: 'cortisolNotDone'
                },
                {
                    name: 'cortisolSign'
                },
                {
                    name: 'cortisolUln'
                },
                {
                    name: 'cortisolUnits'
                }
            ]
        }
    },
    {
        name: 'creatinine',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="creatinineLln"
                notDoneName="creatinineNotDone"
                signName="creatinineSign"
                prependParentName
                ulnName="creatinineUln"
                tableCell
                unitName="creatinineUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'creatinineLln'
                },
                {
                    name: 'creatinineNotDone'
                },
                {
                    name: 'creatinineSign'
                },
                {
                    name: 'creatinineUln'
                },
                {
                    name: 'creatinineUnits'
                }
            ]
        }
    },
    {
        name: 'gammaGlutamylTransferase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="gammaGlutamylTransferaseLln"
                notDoneName="gammaGlutamylTransferaseNotDone"
                signName="gammaGlutamylTransferaseSign"
                prependParentName
                ulnName="gammaGlutamylTransferaseUln"
                tableCell
                unitName="gammaGlutamylTransferaseUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'gammaGlutamylTransferaseLln'
                },
                {
                    name: 'gammaGlutamylTransferaseNotDone'
                },
                {
                    name: 'gammaGlutamylTransferaseSign'
                },
                {
                    name: 'gammaGlutamylTransferaseUln'
                },
                {
                    name: 'gammaGlutamylTransferaseUnits'
                }
            ]
        }
    },
    {
        name: 'lactateDehydrogenase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="lactateDehydrogenaseLln"
                notDoneName="lactateDehydrogenaseNotDone"
                signName="lactateDehydrogenaseSign"
                prependParentName
                ulnName="lactateDehydrogenaseUln"
                tableCell
                unitName="lactateDehydrogenaseUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lactateDehydrogenaseLln'
                },
                {
                    name: 'lactateDehydrogenaseNotDone'
                },
                {
                    name: 'lactateDehydrogenaseSign'
                },
                {
                    name: 'lactateDehydrogenaseUln'
                },
                {
                    name: 'lactateDehydrogenaseUnits'
                }
            ]
        }
    },
    {
        name: 'lipase',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="lipaseLln"
                notDoneName="lipaseNotDone"
                signName="lipaseSign"
                prependParentName
                ulnName="lipaseUln"
                tableCell
                unitName="lipaseUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lipaseLln'
                },
                {
                    name: 'lipaseNotDone'
                },
                {
                    name: 'lipaseSign'
                },
                {
                    name: 'lipaseUln'
                },
                {
                    name: 'lipaseUnits'
                }
            ]
        }
    },
    {
        name: 'potassium',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="potassiumLln"
                notDoneName="potassiumNotDone"
                signName="potassiumSign"
                prependParentName
                ulnName="potassiumUln"
                tableCell
                unitName="potassiumUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'potassiumLln'
                },
                {
                    name: 'potassiumNotDone'
                },
                {
                    name: 'potassiumSign'
                },
                {
                    name: 'potassiumUln'
                },
                {
                    name: 'potassiumUnits'
                }
            ]
        }
    }
];


const chemistryThreeColumns: IGroupedSubformGridCell<Chemistry>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'assessmentPerformed',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'sodium',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="sodiumLln"
                notDoneName="sodiumNotDone"
                signName="sodiumSign"
                prependParentName
                ulnName="sodiumUln"
                tableCell
                unitName="sodiumUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'sodiumLln'
                },
                {
                    name: 'sodiumNotDone'
                },
                {
                    name: 'sodiumSign'
                },
                {
                    name: 'sodiumUln'
                },
                {
                    name: 'sodiumUnits'
                }
            ]
        }
    },
    {
        name: 'totalBilirubin',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="totalBilirubinLln"
                notDoneName="totalBilirubinNotDone"
                signName="totalBilirubinSign"
                prependParentName
                ulnName="totalBilirubinUln"
                tableCell
                unitName="totalBilirubinUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'totalBilirubinLln'
                },
                {
                    name: 'totalBilirubinNotDone'
                },
                {
                    name: 'totalBilirubinSign'
                },
                {
                    name: 'totalBilirubinUln'
                },
                {
                    name: 'totalBilirubinUnits'
                }
            ]
        }
    },
    {
        name: 'totalProtein',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="totalProteinLln"
                notDoneName="totalProteinNotDone"
                signName="totalProteinSign"
                prependParentName
                ulnName="totalProteinUln"
                tableCell
                unitName="totalProteinUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'totalProteinLln'
                },
                {
                    name: 'totalProteinNotDone'
                },
                {
                    name: 'totalProteinSign'
                },
                {
                    name: 'totalProteinUln'
                },
                {
                    name: 'totalProteinUnits'
                }
            ]
        }
    },
    {
        name: 'serumUrea',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="serumUreaLln"
                notDoneName="serumUreaNotDone"
                signName="serumUreaSign"
                prependParentName
                ulnName="serumUreaUln"
                tableCell
                unitName="serumUreaUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'serumUreaLln'
                },
                {
                    name: 'serumUreaNotDone'
                },
                {
                    name: 'serumUreaSign'
                },
                {
                    name: 'serumUreaUln'
                },
                {
                    name: 'serumUreaUnits'
                }
            ]
        }
    },
    {
        name: 'bloodUreaNitrogen',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="bloodUreaNitrogenLln"
                notDoneName="bloodUreaNitrogenNotDone"
                signName="bloodUreaNitrogenSign"
                prependParentName
                ulnName="bloodUreaNitrogenUln"
                tableCell
                unitName="bloodUreaNitrogenUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'bloodUreaNitrogenLln'
                },
                {
                    name: 'bloodUreaNitrogenNotDone'
                },
                {
                    name: 'bloodUreaNitrogenSign'
                },
                {
                    name: 'bloodUreaNitrogenUln'
                },
                {
                    name: 'bloodUreaNitrogenUnits'
                }
            ]
        }
    },
    {
        name: 'uricAcid',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="uricAcidLln"
                notDoneName="uricAcidNotDone"
                signName="uricAcidSign"
                prependParentName
                ulnName="uricAcidUln"
                tableCell
                unitName="uricAcidUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'uricAcidLln'
                },
                {
                    name: 'uricAcidNotDone'
                },
                {
                    name: 'uricAcidSign'
                },
                {
                    name: 'uricAcidUln'
                },
                {
                    name: 'uricAcidUnits'
                }
            ]
        }
    }
];

const thyroidFunctionColumns: IGroupedSubformGridCell<ThyroidFunction>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'assessmentPerformed',
        content: (
            <Input
                component={ValueWithIfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                accessor={performedAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'tsh',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="tshLln"
                notDoneName="tshNotDone"
                signName="tshSign"
                prependParentName
                ulnName="tshUln"
                tableCell
                unitName="tshUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'tshLln'
                },
                {
                    name: 'tshNotDone'
                },
                {
                    name: 'tshSign'
                },
                {
                    name: 'tshUln'
                },
                {
                    name: 'tshUnits'
                }
            ]
        }
    },
    {
        name: 't3',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="t3Lln"
                notDoneName="t3NotDone"
                signName="t3Sign"
                prependParentName
                ulnName="t3Uln"
                tableCell
                unitName="t3Units"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 't3Lln'
                },
                {
                    name: 't3NotDone'
                },
                {
                    name: 't3Sign'
                },
                {
                    name: 't3Uln'
                },
                {
                    name: 't3Units'
                }
            ]
        }
    },
    {
        name: 't4',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="t4Lln"
                notDoneName="t4NotDone"
                signName="t4Sign"
                prependParentName
                ulnName="t4Uln"
                tableCell
                unitName="t4Units"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 't4Lln'
                },
                {
                    name: 't4NotDone'
                },
                {
                    name: 't4Sign'
                },
                {
                    name: 't4Uln'
                },
                {
                    name: 't4Units'
                }
            ]
        }
    }
];

const coagulationColumns: IGroupedSubformGridCell<Coagulation>[] = [
    {
        name: 'eventName',
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="125px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'assessmentPerformed',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'prothombinTime',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="prothombinTimeLln"
                notDoneName="prothombinTimeNotDone"
                signName="prothombinTimeSign"
                prependParentName
                ulnName="prothombinTimeUln"
                tableCell
                unitName="prothombinTimeUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'prothombinTimeLln'
                },
                {
                    name: 'prothombinTimeNotDone'
                },
                {
                    name: 'prothombinTimeSign'
                },
                {
                    name: 'prothombinTimeUln'
                },
                {
                    name: 'prothombinTimeUnits'
                }
            ]
        }
    },
    {
        name: 'internationalNormalisedRatio',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="internationalNormalisedRatioLln"
                notDoneName="internationalNormalisedRatioNotDone"
                signName="internationalNormalisedRatioSign"
                prependParentName
                ulnName="internationalNormalisedRatioUln"
                tableCell
                unitName="internationalNormalisedRatioUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'internationalNormalisedRatioLln'
                },
                {
                    name: 'internationalNormalisedRatioNotDone'
                },
                {
                    name: 'internationalNormalisedRatioSign'
                },
                {
                    name: 'internationalNormalisedRatioUln'
                },
                {
                    name: 'internationalNormalisedRatioUnits'
                }
            ]
        }
    },
    {
        name: 'activatedPartialThromboplastinTime',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="activatedPartialThromboplastinTimeLln"
                notDoneName="activatedPartialThromboplastinTimeNotDone"
                signName="activatedPartialThromboplastinTimeSign"
                prependParentName
                ulnName="activatedPartialThromboplastinTimeUln"
                tableCell
                unitName="activatedPartialThromboplastinTimeUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'activatedPartialThromboplastinTimeLln'
                },
                {
                    name: 'activatedPartialThromboplastinTimeNotDone'
                },
                {
                    name: 'activatedPartialThromboplastinTimeSign'
                },
                {
                    name: 'activatedPartialThromboplastinTimeUln'
                },
                {
                    name: 'activatedPartialThromboplastinTimeUnits'
                }
            ]
        }
    },
    {
        name: 'partialThromboplastinTime',
        content: (
            <Input
                component={BiochemistryDisplay}
                decimalPlaces={2}
                llnName="partialThromboplastinTimeLln"
                notDoneName="partialThromboplastinTimeNotDone"
                signName="partialThromboplastinTimeSign"
                prependParentName
                ulnName="partialThromboplastinTimeUln"
                tableCell
                unitName="partialThromboplastinTimeUnits"
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'partialThromboplastinTimeLln'
                },
                {
                    name: 'partialThromboplastinTimeNotDone'
                },
                {
                    name: 'partialThromboplastinTimeSign'
                },
                {
                    name: 'partialThromboplastinTimeUln'
                },
                {
                    name: 'partialThromboplastinTimeUnits'
                }
            ]
        }
    }
];

const OrganFunctionTreatment: FunctionComponent<IOrganFunctionTreatmentProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Haematology
                    </Typography>
                    <Condition
                        type={OrganFunctionTreatmentDto}
                        condition={hasHaematology}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Haematology}
                            name="haematology"
                            columns={haematologyColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionTreatmentDto}
                        condition={hasNoHaematology}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Haematology Data"
                                description="There is no haematology information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Biochemistry
                    </Typography>
                    <Condition
                        type={OrganFunctionTreatmentDto}
                        condition={hasChemistry}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Chemistry}
                            name="chemistry"
                            columns={chemistryOneColumns}
                            disabled
                        />
                        <br />
                        <GroupedSubformGrid
                            type={Chemistry}
                            name="chemistry"
                            columns={chemistryTwoColumns}
                            disabled
                        />
                        <br />
                        <GroupedSubformGrid
                            type={Chemistry}
                            name="chemistry"
                            columns={chemistryThreeColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionTreatmentDto}
                        condition={hasNoChemistry}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Biochemistry Data"
                                description="There is no biochemistry information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Thyroid Function
                    </Typography>
                    <Condition
                        type={OrganFunctionTreatmentDto}
                        condition={hasThyroidFunction}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ThyroidFunction}
                            name="thyroidFunction"
                            columns={thyroidFunctionColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionTreatmentDto}
                        condition={hasNoThyroidFunction}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Thyroid Function Data"
                                description="There is no thyroid function information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Coagulation
                    </Typography>
                    <Condition
                        type={OrganFunctionTreatmentDto}
                        condition={hasCoagulation}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Coagulation}
                            name="coagulation"
                            columns={coagulationColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={OrganFunctionTreatmentDto}
                        condition={hasNoCoagulation}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Coagulation Data"
                                description="There is no coagulation information associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default OrganFunctionTreatment;
