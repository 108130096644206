import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';
import { FormsContext, FormsLayout } from '@ngt/forms';
import { Navigate, Route, Routes, useRoutes, RouteObject, Outlet } from 'react-router-dom';
import { Patients, Patient, MedicalReview, MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute, MedicalReviewExtensionContext, IMedicalReviewFormMapping, MedicalReviewsByPatientStudyNumberRoute, MedicalReviewByPatientStudyNumberAndRepeatRoute, MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute, QuerySummary } from '@ngt/forms-medical-review';
import AdverseEvents from './containers/form/AdverseEvents';
import ConcomitantMedications from './containers/form/ConcomitantMedications';
import GeneralInformation from './containers/form/GeneralInformation';
import Histopathology from './containers/form/Histopathology';
import Imaging from './containers/form/Imaging';
import MedicalHistory from './containers/form/MedicalHistory';
import NeonTherapy from './containers/form/NeonTherapy';
import OrganFunction from './containers/form/OrganFunction';
import OrganFunctionTreatment from './containers/form/OrganFunctionTreatment';
import Surgery from './containers/form/Surgery';
import SurvivalAndRecurrence from './containers/form/SurvivalAndRecurrence';
import { TrialsExtensionContext, useInstitutions } from '@ngt/forms-trials';
import IFramePage from './containers/IFramePage';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';
import { Box, CircularProgress } from '@mui/material';

const formMapping: Record<string, IMedicalReviewFormMapping> = {
    "adverse-events": {
        components: <AdverseEvents />
    },
    "concomitant-medications": {
        components: <ConcomitantMedications />
    },
    "general-information": {
        components: <GeneralInformation />
    },
    "histopathology": {
        components: <Histopathology />
    },
    "imaging": {
        components: <Imaging />
    },
    "medical-history": {
        components: <MedicalHistory />
    },
    "neon-therapy": {
        components: <NeonTherapy />
    },
    "organ-function": {
        components: <OrganFunction />
    },
    "organ-function-treatment": {
        components: <OrganFunctionTreatment />
    },
    "surgery": {
        components: <Surgery />
    },
    "survival-and-recurrence": {
        components: <SurvivalAndRecurrence />
    }
}

const AppRoute: FunctionComponent = () => {
    const initRef = useRef<boolean>(true);
    const { loading, error } = useInstitutions();

    useEffect(() => {

        console.log('==> init:', initRef.current);
        console.log('==> loading:', loading);

        if (loading && initRef.current) {
            initRef.current = false;
        }
    }, [loading])

    if (initRef.current || loading) {
        return (
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: 'calc(100vh - 347.75px)',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <CircularProgress sx={{ margin: '0 auto 0 auto' }}/>
            </Box>
        )
    }

    if (!loading && error) {
        return (
            <ErrorPage errorType={parseInt(error.errorCode) ?? ErrorTypeEnum.e500} />
        )
    }

    return (
        <Routes>
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/:formDefinitionCode/:formRepeat/*`}
                element={
                    <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    >
                        <QuerySummary />
                    </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/:formDefinitionCode/*`}
                element={
                    <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    >
                        <QuerySummary />
                    </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/queries/*`}
                element={
                    <MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute
                        resolveBeforeLoad
                    >
                        <QuerySummary />
                    </MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/*`}
                element={
                    <MedicalReviewByPatientStudyNumberAndRepeatRoute
                        resolveBeforeLoad
                    >
                        <MedicalReview />
                    </MedicalReviewByPatientStudyNumberAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/*`}
                element={
                    <MedicalReviewsByPatientStudyNumberRoute
                        resolveBeforeLoad
                    >
                        <Patient />
                    </MedicalReviewsByPatientStudyNumberRoute>
                }
            />
            <Route
                path="/query-report/queries"
                element={
                    <IFramePage url="/queries" />
                }
            />
            <Route
                path="/medical-review"
                element={<Patients />}
            />
            <Route path="*" element={<Navigate replace to="/medical-review" />} />
        </Routes>
    )
}

const App: FunctionComponent = () => {
    const formsContext = useContext(FormsContext);
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const medicalReviewExtensionContext = useContext(MedicalReviewExtensionContext);

    return (
        <FormsLayout>
            <AppRoute />
        </FormsLayout>
    );
};

export default App;
