/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import { FieldContext, IInputRenderProps, useScopedField } from '@ngt/forms-core';
import { Tooltip, Typography, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { getParentPropertyPath, IValidationError } from '@ngt/forms';
import { makeStyles } from 'tss-react/mui';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = IInputRenderProps<number, IValidationError>

export interface IBiochemistryDisplayProps extends TextFieldsProps {
    ulnName: string;
    llnName: string;
    decimalPlaces?: number;
    prefix?: ReactNode;
    suffix?: ReactNode;
    signName?: string;
    unitSpecName?: string;
    unitName?: string;
    notDoneName?: string;
    prependParentName?: boolean;
    tableCell?: boolean;
    eligibilityUlnName: string;
    eligibilityUlnInclusive: boolean;
    eligibilityLlnName: string;
    eligibilityLlnInclusive: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    red: {
        color: theme.palette.error.main
    },
    blue: {
        color: theme.palette.info.main
    },
    tooltipUnits: {
        opacity: 0.8,

        '& .MuiTypography-root': {
            fontSize: '1em',
            color: 'white !important'
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */
const subscription = { value: true };

const BiochemistryDisplay: React.FunctionComponent<IBiochemistryDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    decimalPlaces,
    prefix,
    suffix,
    ulnName,
    llnName,
    signName,
    unitSpecName,
    unitName,
    notDoneName,
    prependParentName,
    tableCell,
    eligibilityUlnName,
    eligibilityLlnName,
    eligibilityUlnInclusive,
    eligibilityLlnInclusive,
    ...rest
}) => {
    const { classes } = useStyles();

    const parentContext = React.useContext(FieldContext);

    const computedNotDoneName = React.useMemo(() => {
        if (parentContext?.name && prependParentName === true) {
            const parentName = tableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (notDoneName?.startsWith('[')) {
                return `${parentName}${notDoneName}`;
            }

            return `${parentName}.${notDoneName}`;
        }

        return notDoneName ?? '';
    }, [parentContext?.name, notDoneName, prependParentName, tableCell]);

    const { state: { value: notDoneValue } } = useScopedField<boolean, IValidationError>(computedNotDoneName, subscription, false);

    const computedUlnName = React.useMemo(() => {
        if (parentContext?.name && prependParentName === true) {
            const parentName = tableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (ulnName.startsWith('[')) {
                return `${parentName}${ulnName}`;
            }

            return `${parentName}.${ulnName}`;
        }

        return ulnName;
    }, [parentContext?.name, ulnName, prependParentName, tableCell]);

    const computedEligibilityUlnName = React.useMemo(() => {
        if (parentContext?.name && prependParentName === true) {
            const parentName = tableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (eligibilityUlnName?.startsWith('[')) {
                return `${parentName}${eligibilityUlnName}`;
            }

            return `${parentName}.${eligibilityUlnName}`;
        }

        return eligibilityUlnName;
    }, [parentContext?.name, eligibilityUlnName, prependParentName, tableCell]);

    const computedLlnName = React.useMemo(() => {
        if (parentContext?.name && prependParentName === true) {
            const parentName = tableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (llnName.startsWith('[')) {
                return `${parentName}${llnName}`;
            }

            return `${parentName}.${llnName}`;
        }

        return llnName;
    }, [parentContext?.name, llnName, prependParentName, tableCell]);

    const computedEligibilityLlnName = React.useMemo(() => {
        if (parentContext?.name && prependParentName === true) {
            const parentName = tableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (eligibilityLlnName?.startsWith('[')) {
                return `${parentName}${eligibilityLlnName}`;
            }

            return `${parentName}.${eligibilityLlnName}`;
        }

        return eligibilityLlnName;
    }, [parentContext?.name, eligibilityLlnName, prependParentName, tableCell]);

    const computedSignName = React.useMemo(() => {
        if (parentContext?.name && prependParentName === true) {
            const parentName = tableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (signName?.startsWith('[')) {
                return `${parentName}${signName}`;
            }

            return `${parentName}.${signName}`;
        }

        return signName ?? '';
    }, [parentContext?.name, signName, prependParentName, tableCell]);

    const computedunitSpecName = React.useMemo(() => {
        if (parentContext?.name && prependParentName === true) {
            const parentName = tableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (unitSpecName?.startsWith('[')) {
                return `${parentName}${unitSpecName}`;
            }

            return `${parentName}.${unitSpecName}`;
        }

        return unitSpecName ?? '';
    }, [parentContext?.name, unitSpecName, prependParentName, tableCell]);

    const computedUnitName = React.useMemo(() => {
        if (parentContext?.name && prependParentName === true) {
            const parentName = tableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (unitName?.startsWith('[')) {
                return `${parentName}${unitName}`;
            }

            return `${parentName}.${unitName}`;
        }

        return unitName ?? '';
    }, [parentContext?.name, unitName, prependParentName, tableCell]);

    const { state: { value: ulnValue } } = useScopedField<number, IValidationError>(computedUlnName, subscription, false);
    const { state: { value: llnValue } } = useScopedField<number, IValidationError>(computedLlnName, subscription, false);
    const { state: { value: eligibilityUlnValue } } = useScopedField<number, IValidationError>(computedEligibilityUlnName, subscription, false);
    const { state: { value: eligibilityLlnValue } } = useScopedField<number, IValidationError>(computedEligibilityLlnName, subscription, false);
    const { state: { value: signValue } } = useScopedField<string, IValidationError>(computedSignName, subscription, false);
    const { state: { value: unitSpecValue } } = useScopedField<string, IValidationError>(computedunitSpecName, subscription, false);
    const { state: { value: unitValue } } = useScopedField<string, IValidationError>(computedUnitName, subscription, false);

    const calculatedUnitValue = unitValue?.toLowerCase() === 'other' && unitSpecValue ? unitSpecValue : unitValue;

    const calculatedUnitTypography: ReactNode = (
        <Typography color="textSecondary" variant="caption" component="span"> {calculatedUnitValue}</Typography>
    );

    const signTypography: ReactNode = (
        <Typography component="span">{signValue} </Typography>
    );

    const convertedValue = decimalPlaces ? value?.toFixed(decimalPlaces) : value;

    if (notDoneValue || ((notDoneName === null || notDoneName === undefined) && (value === null || value === undefined))) {
        return (
            <Typography component="span">
                Not Done
            </Typography>
        );
    }

    const tooltip = (llnValue !== null && llnValue !== undefined) || (ulnValue !== null && ulnValue !== undefined) || (eligibilityLlnValue !== null && eligibilityLlnValue !== undefined) || (eligibilityUlnValue !== null && eligibilityUlnValue !== undefined) ?
        (
            <>
                {
                    ((llnValue !== null && llnValue !== undefined) || (ulnValue !== null && ulnValue !== undefined)) && (
                        <>
                            Limits:
                            <div>
                                {
                                    (llnValue !== null && llnValue !== undefined) && (
                                        <div>
                                            - LLN: {llnValue} <small className={classes.tooltipUnits}>{calculatedUnitValue ? calculatedUnitValue : suffix}</small>
                                        </div>
                                    )
                                }
                                {
                                    (ulnValue !== null && ulnValue !== undefined) && (
                                        <div>
                                            - ULN: {ulnValue} <small className={classes.tooltipUnits}>{calculatedUnitValue ? calculatedUnitValue : suffix}</small>
                                        </div>
                                    )
                                }
                            </div>
                            <br />
                        </>
                    )
                }

                {
                    ((eligibilityLlnValue !== null && eligibilityLlnValue !== undefined) || (eligibilityUlnValue !== null && eligibilityUlnValue !== undefined)) && (
                        <>
                            Eligibility:
                            <div>
                                {
                                    (eligibilityLlnValue !== null && eligibilityLlnValue !== undefined) && (
                                        <div>
                                            - Value must be {(eligibilityLlnInclusive ?? false) ? '≥' : '>'} {eligibilityLlnValue} <small className={classes.tooltipUnits}>{calculatedUnitValue ? calculatedUnitValue : suffix}</small>
                                        </div>
                                    )
                                }
                                {
                                    (eligibilityUlnValue !== null && eligibilityUlnValue !== undefined) && (
                                        <div>
                                            - Value must be {(eligibilityUlnInclusive ?? false) ? '≤' : '<'} {eligibilityUlnValue} <small className={classes.tooltipUnits}>{calculatedUnitValue ? calculatedUnitValue : suffix}</small>
                                        </div>
                                    )
                                }
                            </div>
                        </>

                    )
                }
            </>
        ) :
        null

    if (!notDoneValue && value != null) {

        const outsideUln = (ulnValue !== null && ulnValue !== undefined) && (!signValue || signValue === '' || signValue === '<' || signValue === '<=') && value > ulnValue;
        const outsideLln = (llnValue !== null && llnValue !== undefined) && (!signValue || signValue === '' || signValue === '>' || signValue === '>=') && value < llnValue;
        const outsideEligibilityUln = (eligibilityUlnValue !== null && eligibilityUlnValue !== undefined) && (value > eligibilityUlnValue || (value == eligibilityUlnValue && eligibilityUlnInclusive));
        const outsideEligibilityLln = (eligibilityLlnValue !== null && eligibilityLlnValue !== undefined) && (value < eligibilityLlnValue || (value == eligibilityLlnValue && eligibilityLlnInclusive));

        if (outsideUln || outsideLln) {
            return (
                <Tooltip
                    title={
                        <>
                            {outsideUln ? "Value is over the Upper Limit of Normal" : "Value is below the Lower Limit of Normal"}
                            <br /><br />
                            {tooltip}
                        </>
                    }
                >
                    <Typography component="span">
                        {signValue ? signTypography : prefix}<strong className={outsideUln ? classes.red : classes.blue}>{convertedValue}</strong>{calculatedUnitValue ? calculatedUnitTypography : suffix}{outsideEligibilityLln || outsideEligibilityUln ? ' *' : ''}
                    </Typography>
                </Tooltip>
            );
        }


        if (tooltip) {
            return (
                <Tooltip title={tooltip}>
                    <Typography component="span">
                        {signValue ? signTypography : prefix}{convertedValue}{calculatedUnitValue ? calculatedUnitTypography : suffix}{outsideEligibilityLln || outsideEligibilityUln ? ' *' : ''}
                    </Typography>
                </Tooltip>
            );
        }


        return (
            <Typography component="span">
                {signValue ? signTypography : prefix}{convertedValue}{calculatedUnitValue ? calculatedUnitTypography : suffix}{outsideEligibilityLln || outsideEligibilityUln ? ' *' : ''}
            </Typography>
        );
    }

    return (
        <Typography component="span">
            N/A
        </Typography>
    );

}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default BiochemistryDisplay;
