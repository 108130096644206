import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Theme, Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, IUpdatedFormPropertyStyler } from '@ngt/forms-medical-review';
import { GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay, FieldProvider, FormLabel } from '@ngt/forms';
import { Surgery as SurgeryDto, BreastUltrasound, SlnbResult } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, FormContext, IFormState, useForm, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { DateTime } from 'luxon';

import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import ValueWithIfElseDisplay from '../../components/form/input/display/ValueWithIfElseDisplay';
import CoalesceDisplay from '../../components/form/input/display/CoalesceDisplay';
import DateHighlightDisplay from '../../components/form/input/display/DateHighlightDisplay';
import { IGroupedFieldStyleColumns, IGroupedFieldStyleProps } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

interface ISurgeryProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const dimension2NullAccessor = (value?: number) => value && value > 0 ? 'Not Done' : null;

const dimension2Accessor = (value?: number) => value || value === 0 ? <>{value} <Typography color="textSecondary" variant="caption" component="span"> mm</Typography></> : null;

const nodesAccessor = (value?: number) => value || value === 0 ? <>{value} <Typography color="textSecondary" variant="caption" component="span"> nodes</Typography></> : null;

const histopathologyPercentageTilAccessor = (value?: number) => value ? <>{value} <Typography color="textSecondary" variant="caption" component="span"> %</Typography></> : null;

const breastUltrasoundColumns: IGroupedSubformGridCell<BreastUltrasound>[] = [
    {
        name: 'timepoint',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'side',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'location',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dimensionOne',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    },
    {
        name: 'dimensionTwo',
        content: (
            <Input
                component={CoalesceDisplay}
                ifNullName="dimension2NotDone"
                ifNullAccessor={dimension2NullAccessor}
                accessor={dimension2Accessor}
            />
        )
    },
    {
        name: 'response',
        content: (
            <Input component={TextDisplay} />
        )
    }
];

const slnbResultColumns: IGroupedSubformGridCell<SlnbResult>[] = [
    {
        name: 'nodeNumber',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'location',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'tumourInvolvement',
        content: (
            <Input component={TextDisplay} />
        )
    }
];

const conditionSubscription = { value: true };


const hasPresurgeryCrf: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.presurgeryEventCrfId;
const missingPresurgeryCrf: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.presurgeryEventCrfId;

const hasSurgeryCrf: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryEventCrfId;
const missingSurgeryCrf: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.surgeryEventCrfId;

const hasHistopathologyCrf: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.histopathologyEventCrfId;
const missingHistopathologyCrf: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.histopathologyEventCrfId;


const hasPresurgeryUltrasound: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => value?.presurgeryUltrasoundPerformed === 'Yes';
const hasPresurgeryMammogram: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => value?.presurgeryMammogramPerformed === 'Yes';


const hasSurgery: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => value?.surgeryPerformed === 'Yes';
const hasNoSurgery: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryPerformed && value?.surgeryPerformed !== 'Yes';


const hasSlnb: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => value?.surgerySlnbPerformed === 'Yes';

const hasAxillaryDissection: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => value?.surgeryAxillaryDissectionPerformed === 'Yes';


const hasPdlTesting: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => value?.histopathologyPdlCompleted === 'Yes';

const hasResidualDisease: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.histopathologyResidualDiseaseNone;

const hasInvasiveResidualDisease: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => value?.histopathologyResidualDiseaseInvasive === true;

const hasNoInvasiveResidualDisease: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.histopathologyResidualDiseaseInvasive;

const hasSurgeryBreastConservingSurgeryDate: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryBreastConservingSurgeryDate;
const hasSurgeryBreastConservingSurgeryReexcisionOneDate: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryBreastConservingSurgeryReexcisionOneDate;
const hasSurgerySkinSparingMastectomyDate: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgerySkinSparingMastectomyDate;
const hasSurgeryMastectomyReconstructionDate: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryMastectomyReconstructionDate;
const hasSurgeryBreastConservingSurgeryReexcisionTwoDate: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryBreastConservingSurgeryReexcisionTwoDate;
const hasSurgeryMastectomyDate: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryMastectomyDate;
const hasSurgeryOncoplasticSurgeryDate: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryOncoplasticSurgeryDate;
const hasSurgeryOther: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryOther && !!value?.surgeryOtherDate;

const applyHighlight = ({ value }: IFormState<SurgeryDto>, val?: string | null) => {

    // check if the value is the earliest surgery date
    const dates: Array<string | undefined> = [
        value?.surgeryBreastConservingSurgeryDate,
        value?.surgeryBreastConservingSurgeryReexcisionOneDate,
        value?.surgerySkinSparingMastectomyDate,
        value?.surgeryMastectomyReconstructionDate,
        value?.surgeryBreastConservingSurgeryReexcisionTwoDate,
        value?.surgeryMastectomyDate,
        value?.surgeryOncoplasticSurgeryDate,
        value?.surgeryOtherDate
    ].filter(d => !!d)?.sort();

    if (!dates || dates.length === 0 || !dates[0] || !value) {
        return false;
    }

    return dates[0] === val;
}

const condition = (value: any) => value === 'Yes';

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;

const subscription = { value: true };

const ResidualDisease: FunctionComponent = () => {
    const { state: { value: invasive } } = useScopedField<boolean>('histopathologyResidualDiseaseInvasive', subscription, true);
    const { state: { value: inSitu } } = useScopedField<boolean>('histopathologyResidualDiseaseInSitu', subscription, true);
    const { state: { value: none } } = useScopedField<boolean>('histopathologyResidualDiseaseNone', subscription, true);

    if (!invasive && !inSitu && !none) {
        return (
            <Typography>
                Not Done
            </Typography>
        );
    }

    return (
        <Typography>
            {
                invasive && (
                    <div>
                        Invasive
                    </div>
                )
            }
            {
                inSitu && (
                    <div>
                        <em>In situ</em>
                    </div>
                )
            }
            {
                none && (
                    <div>
                        None
                    </div>
                )
            }
        </Typography>
    );
};


const presurgeryUltrasoundHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'presurgeryUltrasoundDate'
        },
        {
            name: 'presurgeryUltrasoundNotDoneReason'
        }
    ]
};


const presurgeryUltrasoundSuspiciousNodesHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'presurgeryUltrasoundSuspiciousNodeCount'
        }
    ]
};


const presurgeryMammogramHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'presurgeryMammogramDate'
        },
        {
            name: 'presurgeryMammogramNotDoneReason'
        }
    ]
};


const presurgeryMammogramSuspiciousNodesHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'presurgeryMammogramSuspiciousNodeCount'
        }
    ]
};


const surgeryPerformedHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'surgeryNotDoneReason'
        }
    ]
};


const surgeryLastTreatmentHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'surgeryLastTreatmentDate'
        }
    ]
};


const surgeryAxillaryDissectionHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'surgeryAxillaryDissectionDate'
        }
    ]
};


const surgeryBiopsyHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'surgeryBiopsyDate'
        }
    ]
};

const Surgery: FunctionComponent<ISurgeryProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Radiological Response
                    </Typography>
                    <Condition
                        type={SurgeryDto}
                        condition={hasPresurgeryCrf}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Typography variant="h3" className={classes.subtitle}>
                            Ultrasound
                        </Typography>
                        <GroupedField
                            name="presurgeryUltrasoundPerformed"
                            component={ValueWithIfElseDisplay}
                            ifName="presurgeryUltrasoundDate"
                            elseName="presurgeryUltrasoundNotDoneReason"
                            ifAccessor={dateAccessor}
                            GroupStyleProps={groupStyleProps}
                            condition={condition}
                            UpdatedFormPropertyStylerProps={presurgeryUltrasoundHighlighting}
                        />
                        <Condition
                            type={SurgeryDto}
                            condition={hasPresurgeryUltrasound}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="presurgeryUltrasoundNewLeisons"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="presurgeryUltrasoundSuspiciousNodesIdentified"
                                component={ValueWithIfElseDisplay}
                                ifName="presurgeryUltrasoundSuspiciousNodeCount"
                                GroupStyleProps={groupStyleProps}
                                condition={condition}
                                ifAccessor={nodesAccessor}
                                UpdatedFormPropertyStylerProps={presurgeryUltrasoundSuspiciousNodesHighlighting}
                            />
                            <GroupedSubformGrid
                                type={BreastUltrasound}
                                name="presurgeryBreastUltrasound"
                                columns={breastUltrasoundColumns}
                                disabled
                            />
                        </Condition>
                        <Typography variant="h3" className={classes.subtitle}>
                            Mammogram
                        </Typography>
                        <GroupedField
                            name="presurgeryMammogramPerformed"
                            component={ValueWithIfElseDisplay}
                            ifName="presurgeryMammogramDate"
                            elseName="presurgeryMammogramNotDoneReason"
                            ifAccessor={dateAccessor}
                            GroupStyleProps={groupStyleProps}
                            condition={condition}
                            UpdatedFormPropertyStylerProps={presurgeryMammogramHighlighting}
                        />
                        <Condition
                            type={SurgeryDto}
                            condition={hasPresurgeryMammogram}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="presurgeryMammogramNewLeisons"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="presurgeryMammogramSuspiciousNodesIdentified"
                                component={ValueWithIfElseDisplay}
                                ifName="presurgeryMammogramSuspiciousNodeCount"
                                GroupStyleProps={groupStyleProps}
                                condition={condition}
                                UpdatedFormPropertyStylerProps={presurgeryMammogramSuspiciousNodesHighlighting}
                            />
                        </Condition>
                    </Condition>
                    <Condition
                        type={SurgeryDto}
                        condition={missingPresurgeryCrf}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Pre-surgery CRF Data"
                                description="There is no pre-surgery CRF associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Surgery
                    </Typography>
                    <Condition
                        type={SurgeryDto}
                        condition={hasSurgeryCrf}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="surgeryPerformed"
                            component={ValueWithIfElseDisplay}
                            elseName="surgeryNotDoneReason"
                            GroupStyleProps={groupStyleProps}
                            condition={condition}
                            UpdatedFormPropertyStylerProps={surgeryPerformedHighlighting}
                        />
                        <Condition
                            type={SurgeryDto}
                            condition={hasSurgery}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="surgeryLastTreatmentDate"
                                component={DateDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <Typography variant="h3" className={classes.subtitle}>
                                Surgery Management
                            </Typography>
                            <Condition
                                type={SurgeryDto}
                                condition={hasSurgeryBreastConservingSurgeryDate}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="surgeryBreastConservingSurgeryDate"
                                    component={DateHighlightDisplay}
                                    comparisonDateName="surgeryLastTreatmentDate"
                                    days={28}
                                    GroupStyleProps={groupStyleProps}
                                    UpdatedFormPropertyStylerProps={surgeryLastTreatmentHighlighting}
                                    applyHighlight={applyHighlight}
                                />
                            </Condition>
                            <Condition
                                type={SurgeryDto}
                                condition={hasSurgeryBreastConservingSurgeryReexcisionOneDate}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="surgeryBreastConservingSurgeryReexcisionOneDate"
                                    component={DateHighlightDisplay}
                                    comparisonDateName="surgeryLastTreatmentDate"
                                    days={28}
                                    GroupStyleProps={groupStyleProps}
                                    UpdatedFormPropertyStylerProps={surgeryLastTreatmentHighlighting}
                                    applyHighlight={applyHighlight}
                                />
                            </Condition>
                            <Condition
                                type={SurgeryDto}
                                condition={hasSurgeryBreastConservingSurgeryReexcisionTwoDate}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="surgeryBreastConservingSurgeryReexcisionTwoDate"
                                    component={DateHighlightDisplay}
                                    comparisonDateName="surgeryLastTreatmentDate"
                                    days={28}
                                    GroupStyleProps={groupStyleProps}
                                    UpdatedFormPropertyStylerProps={surgeryLastTreatmentHighlighting}
                                    applyHighlight={applyHighlight}
                                />
                            </Condition>
                            <Condition
                                type={SurgeryDto}
                                condition={hasSurgeryMastectomyDate}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="surgeryMastectomyDate"
                                    component={DateHighlightDisplay}
                                    comparisonDateName="surgeryLastTreatmentDate"
                                    days={28}
                                    GroupStyleProps={groupStyleProps}
                                    UpdatedFormPropertyStylerProps={surgeryLastTreatmentHighlighting}
                                    applyHighlight={applyHighlight}
                                />
                            </Condition>
                            <Condition
                                type={SurgeryDto}
                                condition={hasSurgerySkinSparingMastectomyDate}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="surgerySkinSparingMastectomyDate"
                                    component={DateHighlightDisplay}
                                    comparisonDateName="surgeryLastTreatmentDate"
                                    days={28}
                                    GroupStyleProps={groupStyleProps}
                                    UpdatedFormPropertyStylerProps={surgeryLastTreatmentHighlighting}
                                    applyHighlight={applyHighlight}
                                />
                            </Condition>
                            <Condition
                                type={SurgeryDto}
                                condition={hasSurgeryMastectomyReconstructionDate}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="surgeryMastectomyReconstructionDate"
                                    component={DateHighlightDisplay}
                                    comparisonDateName="surgeryLastTreatmentDate"
                                    days={28}
                                    GroupStyleProps={groupStyleProps}
                                    UpdatedFormPropertyStylerProps={surgeryLastTreatmentHighlighting}
                                    applyHighlight={applyHighlight}
                                />
                            </Condition>
                            <Condition
                                type={SurgeryDto}
                                condition={hasSurgeryOncoplasticSurgeryDate}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="surgeryOncoplasticSurgeryDate"
                                    component={DateHighlightDisplay}
                                    comparisonDateName="surgeryLastTreatmentDate"
                                    days={28}
                                    GroupStyleProps={groupStyleProps}
                                    UpdatedFormPropertyStylerProps={surgeryLastTreatmentHighlighting}
                                    applyHighlight={applyHighlight}
                                />
                            </Condition>
                            <Condition
                                type={SurgeryDto}
                                condition={hasSurgeryOther}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    label={
                                        <InputOnlyField
                                            name="surgeryOther"
                                            component={TextDisplay}
                                            FieldProps={{ prependParentName: false }}
                                        />
                                    }
                                    name="surgeryOtherDate"
                                    component={DateHighlightDisplay}
                                    comparisonDateName="surgeryLastTreatmentDate"
                                    days={28}
                                    GroupStyleProps={groupStyleProps}
                                    UpdatedFormPropertyStylerProps={surgeryLastTreatmentHighlighting}
                                    applyHighlight={applyHighlight}
                                />
                            </Condition>
                            <Typography variant="h3" className={classes.subtitle}>
                                Sentinal Lymph Node Biopsy
                            </Typography>
                            <GroupedField
                                name="surgerySlnbPerformed"
                                component={ValueWithIfElseDisplay}
                                ifName="surgerySlnbDate"
                                ifAccessor={dateAccessor}
                                GroupStyleProps={groupStyleProps}
                                condition={condition}
                            />
                            <Condition
                                type={SurgeryDto}
                                condition={hasSlnb}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >

                                <GroupedField
                                    name="surgerySnRemoved"
                                    component={NumericDisplay}
                                    suffix={<Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>}
                                    GroupStyleProps={groupStyleProps}
                                />
                                <GroupedField
                                    name="surgeryNonSnRemoved"
                                    component={NumericDisplay}
                                    suffix={<Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>}
                                    GroupStyleProps={groupStyleProps}
                                />
                                <GroupedSubformGrid
                                    type={SlnbResult}
                                    name="surgerySlnbResults"
                                    columns={slnbResultColumns}
                                    disabled
                                />
                            </Condition>
                            <Typography variant="h3" className={classes.subtitle}>
                                Axillary Dissection
                            </Typography>
                            <GroupedField
                                name="surgeryAxillaryDissectionPerformed"
                                component={ValueWithIfElseDisplay}
                                ifName="surgeryAxillaryDissectionDate"
                                ifAccessor={dateAccessor}
                                GroupStyleProps={groupStyleProps}
                                condition={condition}
                                UpdatedFormPropertyStylerProps={surgeryAxillaryDissectionHighlighting}
                            />
                            <Condition
                                type={SurgeryDto}
                                condition={hasAxillaryDissection}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <FieldProvider name="surgeryAxillaryDissectionNodesRemoved">
                                                    <FormLabel />
                                                </FieldProvider>
                                            </TableCell>
                                            <TableCell>
                                                <FieldProvider name="surgeryAxillaryDissectionPositiveNodes">
                                                    <FormLabel />
                                                </FieldProvider>
                                            </TableCell>
                                            <TableCell>
                                                <FieldProvider name="surgeryAxillaryRtPlanned">
                                                    <FormLabel />
                                                </FieldProvider>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <InputOnlyField
                                                    name="surgeryAxillaryDissectionNodesRemoved"
                                                    component={NumericDisplay}
                                                    suffix={<Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <InputOnlyField
                                                    name="surgeryAxillaryDissectionPositiveNodes"
                                                    component={NumericDisplay}
                                                    suffix={<Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <InputOnlyField
                                                    name="surgeryAxillaryRtPlanned"
                                                    component={TextDisplay}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Condition>
                        </Condition>
                        <Condition
                            type={SurgeryDto}
                            condition={hasNoSurgery}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="surgeryBiopsyPerformed"
                                component={ValueWithIfElseDisplay}
                                ifName="surgeryBiopsyDate"
                                ifAccessor={dateAccessor}
                                GroupStyleProps={groupStyleProps}
                                condition={condition}
                                UpdatedFormPropertyStylerProps={surgeryBiopsyHighlighting}
                            />
                        </Condition>
                    </Condition>
                    <Condition
                        type={SurgeryDto}
                        condition={missingSurgeryCrf}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Surgery CRF Data"
                                description="There is no surgery CRF associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Histopathology
                    </Typography>
                    <Condition
                        type={SurgeryDto}
                        condition={hasHistopathologyCrf}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="histopathologyNumberOfParaffinBlocks"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="histopathologyResidualDiseaseInvasive"
                            component={ResidualDisease}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={SurgeryDto}
                            condition={hasResidualDisease}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Typography variant="h3" className={classes.subtitle}>
                                Tumour
                            </Typography>
                            <GroupedField
                                name="histopathologyType"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="histopathologyGrade"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="histopathologyLymphovascularInvasion"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="histopathologyFocality"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="histopathologyLargestUnilateralMeasurement"
                                component={NumericDisplay}
                                suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                                GroupStyleProps={groupStyleProps}
                            />
                            <Typography variant="h3" className={classes.subtitle}>
                                Margins
                            </Typography>
                            <GroupedField
                                name="histopathologyMarginsClearOfInvasiveBreastCancer"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="histopathologyMarginsClearOfInSitu"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                        <Typography variant="h3" className={classes.subtitle}>
                            Pathologic yTNM Staging
                        </Typography>
                        <GroupedField
                            name="histopathologyTStage"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="histopathologyNStage"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Typography variant="h3" className={classes.subtitle}>
                            Tumour Infiltrating Lymphocyte
                        </Typography>
                        <Condition
                            type={SurgeryDto}
                            condition={hasInvasiveResidualDisease}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="histopathologyPercentageTil"
                                component={CoalesceDisplay}
                                ifNullName="histopathologyPercentageTil"
                                ifNullAccessor={() => <>Not Done</>}
                                accessor={histopathologyPercentageTilAccessor}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                        <Condition
                            type={SurgeryDto}
                            condition={hasNoInvasiveResidualDisease}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="histopathologyPercentageTil"
                                component={TextDisplay}
                                convert={(value) => "Not Applicable"}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                        <Condition
                            type={SurgeryDto}
                            condition={hasResidualDisease}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Typography variant="h3" className={classes.subtitle}>
                                Programmed Death-Ligand 1 (PDL-1)
                            </Typography>
                            <GroupedField
                                name="histopathologyPdlCompleted"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <Condition
                                type={SurgeryDto}
                                condition={hasPdlTesting}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="histopathologyPdlAssayType"
                                    component={TextDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                                <GroupedField
                                    name="histopathologyPdlResults"
                                    component={TextDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                            </Condition>
                        </Condition>
                        <Typography variant="h3" className={classes.subtitle}>
                            Residual Cancer Burden
                        </Typography>
                        <Typography variant="h4" className={classes.subtitle}>
                            Primary Tumour Bed
                        </Typography>
                        <GroupedField
                            name="histopathologyRcbDimensionOne"
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="histopathologyRcbDimensionTwo"
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="histopathologyRcbCancerCellularity"
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> %</Typography>}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="histopathologyRcbInSituDisease"
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> %</Typography>}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Typography variant="h4" className={classes.subtitle}>
                            Lymph Nodes
                        </Typography>
                        <GroupedField
                            name="histopathologyRcbNumberPositiveNodes"
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="histopathologyRcbLargestDiameter"
                            component={NumericDisplay}
                            suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Typography variant="h4" className={classes.subtitle}>
                            Residual Cancer Burden
                        </Typography>
                        <GroupedField
                            name="histopathologyRcb"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="histopathologyRcbClass"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={SurgeryDto}
                        condition={missingHistopathologyCrf}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Histopathology CRF Data"
                                description="There is no histopathology CRF associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Surgery;
