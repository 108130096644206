/* eslint-disable spaced-comment */
/// <reference types="react-scripts" />
import bctLogo from './assets/img/bct.png';
import * as Dtos from "./apis/dtos";
import { Forms } from '@ngt/forms';
import { responsiveFontSizes } from '@mui/material';
import { mutate, cache } from 'swr';
import { MedicalReviewExtension } from '@ngt/forms-medical-review';
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import Footer from './components/layout/Footer';
import { PostHasPermissions } from './apis/dtos';
import { faFileChartLine } from '@fortawesome/pro-duotone-svg-icons';
import { TrialsExtension } from '@ngt/forms-trials';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const user = global.AUTH as Dtos.UserSingleResponse;
const formMetadata = global.FORM_METADATA as Dtos.GetFormMetadataResponse;

if (!user || !user.user) {
    if (global.location.search) {
        global.location.href = '/auth/openiddict' + global.location.search;
    } else {
        global.location.href = '/auth/openiddict';
    }
}

const primaryColor = '#562c85';
const secondaryColor = '#e8078c';

const forms = new Forms({
    initialAuthentication: user,
    dtos: Dtos,
    baseRoute: '/medical-review',
    extensions: [
        new TrialsExtension({
            organisationLogo: bctLogo,
            organisationName: "Breast Cancer Trials",
            trialName: "Neo-N Medical Review",
            menuItems: async (auth, forms) => {
                const menuItems = [
                    {
                        url: '/medical-review',
                        label: 'Participants',
                        external: false,
                        newTab: false,
                        icon: faUser
                    }
                ]

                if (auth && forms.serviceStackClient) {
                    const response = await forms.serviceStackClient.post(new PostHasPermissions({
                        permissions: [Dtos.MedicalReviewPermission.Access]
                    }));

                    if (response.permissions[Dtos.MedicalReviewPermission.Access]) {
                        menuItems.push({
                            url: '/query-report/queries',
                            label: 'Query Report',
                            external: false,
                            newTab: false,
                            icon: faFileChartLine
                        })
                    }
                }

                return menuItems;
            },
            footer: <Footer />,
            routeParameters: {
                patientStudyNumber: 'patientStudyNumber'
            }
        }),
        new MedicalReviewExtension({
            routeParameters: {
                medicalReviewRepeat: 'medicalReviewRepeat'
            },
            createPatientsRouteFn: (search) => `/medical-review?${search}`,
            createPatientRouteFn: (patient) => `/medical-review/${patient.studyNumber}`,
            createMedicalReviewRouteFn: (patient, medicalReview) => `/medical-review/${patient.studyNumber}/${medicalReview.repeat}`,
            createMedicalReviewFormRouteFn: (patient, medicalReview, formDefinition, formValidation) => formValidation?.metadata?.Repeat && formValidation?.metadata?.Repeat > 1 ? `/medical-review/${patient.studyNumber}/${medicalReview.repeat}/${formDefinition.code}/${formValidation?.metadata?.Repeat}` : `/medical-review/${patient.studyNumber}/${medicalReview.repeat}/${formDefinition.code}`,
            createMedicalReviewQueriesRouteFn: (patient, medicalReview) => `/medical-review/${patient.studyNumber}/${medicalReview.repeat}/queries`,
        })
    ],
    formMetadata: formMetadata?.formMetadata,
    themeOptions: {
        beforeThemeCreated: themeOptions => {
            return {
                ...themeOptions,
                palette: {
                    ...themeOptions.palette,
                    primary: {
                        main: primaryColor
                    },
                    secondary: {
                        main: secondaryColor
                    }
                },
                typography: {
                    ...themeOptions.typography,
                    h1: {
                        ...(themeOptions?.typography as any)?.h1,
                        color: secondaryColor
                    },
                    h2: {
                        ...(themeOptions?.typography as any)?.h2,
                        color: primaryColor
                    },
                    h3: {
                        ...(themeOptions?.typography as any)?.h3,
                        color: secondaryColor
                    },
                    h4: {
                        ...(themeOptions?.typography as any)?.h4,
                        color: primaryColor
                    },
                    h5: {
                        ...(themeOptions?.typography as any)?.h5,
                        color: secondaryColor
                    },
                    h6: {
                        ...(themeOptions?.typography as any)?.h6,
                        color: primaryColor
                    }
                }
            };
        },
        afterThemeCreated: theme => {
            return responsiveFontSizes(theme);
        }
    }
});

forms.serviceStackClient.responseFilter = (res: Response) => {
    const userKey = [`${forms.baseRoute}/user`, forms];
    const userResponse: Dtos.UserSingleResponse | undefined = cache.get(userKey);

    if (res.headers.has('openiddict-user-status') &&
        res.headers.get('openiddict-user-status') === 'unauthenticated' &&
        userResponse) {
        mutate(userKey, userResponse, true);
    }
    else if (res.headers.has('openiddict-user-update') &&
        res.headers.get('openiddict-user-update') === 'true') {
        mutate(userKey, userResponse, true);
    }
}

forms.serviceStackClient.exceptionFilter = (res: Response, error: any) => {
    if (res.status === 401) {
        global.location.href = `/auth/openiddict?redirect=${encodeURIComponent(global.location.pathname + global.location.search)}`;
    }
};

export default forms;