import React, { FunctionComponent, ReactNode } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, OpenClinicaFormDisplay, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-medical-review';
import { GroupedFields, Input, TextDisplay, DateDisplay, IValidationError, getParentPropertyPath } from '@ngt/forms';
import { SurvivalAndRecurrence as SurvivalAndRecurrenceDto, SurvivalFollowUpItem, BreastCancerRecurrenceItem, SecondPrimaryMalignancyItem, DeathReportItem } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, FieldContext, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { Theme, Typography, Box, SxProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface ISurvivalAndRecurrenceProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    }
}));

const VisitNumberHighlight: FunctionComponent<{ children?: ReactNode, sx?: SxProps }> = ({ children, sx }) => {
    const parentContext = React.useContext(FieldContext);

    const parentName = getParentPropertyPath(parentContext?.name);

    const { state: { value: visitNumber } } = useScopedField<number, IValidationError>(`${parentName}.visitNumber`, conditionSubscription, false);

    return (
        <Box
            sx={{
                padding: '1rem',
                height: '100%',
                background: `${(visitNumber ?? 0) % 2 == 0 ? 'inherit' : 'rgba(194, 181, 208, 0.4)'}`
            }}
        >
            {children}
        </Box>
    );
}

const survivalFollowUpColumns: IGroupedSubformGridCell<SurvivalFollowUpItem>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>,
    },
    {
        name: 'visitNumber',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        
        sortValue: (row) => row?.visitNumber,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'dateOfVisit',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfVisit,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'methodOfReview',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.methodOfReview,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'changesObserved',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.changesObserved,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'antiCancerTreatment',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.antiCancerTreatment,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'dateCommenced',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.dateCommenced,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'dateCompleted',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.dateCompleted,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    }
];

const breastCancerRecurrenceColumns: IGroupedSubformGridCell<BreastCancerRecurrenceItem>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'type',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.type
    },
    {
        name: 'location',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.location
    },
    {
        name: 'dateOfFirstSuspicion',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfFirstSuspicion
    },
    {
        name: 'dateOfConfirmation',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfConfirmation
    },
    {
        name: 'timeOnStudy',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.timeOnStudy
    },
    {
        name: 'typeOfConfirmation',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.typeOfConfirmation
    }
];

const secondPrimaryMalignancyColumns: IGroupedSubformGridCell<SecondPrimaryMalignancyItem>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'location',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.location
    },
    {
        name: 'dateOfFirstSuspicion',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfFirstSuspicion
    },
    {
        name: 'dateOfConfirmation',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfConfirmation
    },
    {
        name: 'timeOnStudy',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.timeOnStudy
    },
    {
        name: 'typeOfConfirmation',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.typeOfConfirmation
    }
];

const deathReportColumns: IGroupedSubformGridCell<DeathReportItem>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'dateOfDeath',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfDeath
    },
    {
        name: 'timeOnStudy',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.timeOnStudy
    },
    {
        name: 'causeOfDeath',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.causeOfDeath
    }
];

const hasNoSurvivalFollowUp: ICondition<SurvivalAndRecurrenceDto> = ({ formState: { value } = {} }) => !value?.survivalFollowUpItems || value?.survivalFollowUpItems?.length === 0;
const hasSurvivalFollowUp: ICondition<SurvivalAndRecurrenceDto> = ({ formState: { value } = {} }) => !!value?.survivalFollowUpItems && value?.survivalFollowUpItems?.length > 0;

const hasNoBreastCancerRecurrence: ICondition<SurvivalAndRecurrenceDto> = ({ formState: { value } = {} }) => !value?.breastCancerRecurrenceItems || value?.breastCancerRecurrenceItems?.length === 0;
const hasBreastCancerRecurrence: ICondition<SurvivalAndRecurrenceDto> = ({ formState: { value } = {} }) => !!value?.breastCancerRecurrenceItems && value?.breastCancerRecurrenceItems?.length > 0;

const hasNoSecondPrimaryMalignancy: ICondition<SurvivalAndRecurrenceDto> = ({ formState: { value } = {} }) => !value?.secondPrimaryMalignancyItems || value?.secondPrimaryMalignancyItems?.length === 0;
const hasSecondPrimaryMalignancy: ICondition<SurvivalAndRecurrenceDto> = ({ formState: { value } = {} }) => !!value?.secondPrimaryMalignancyItems && value?.secondPrimaryMalignancyItems?.length > 0;

const hasNoDeathReport: ICondition<SurvivalAndRecurrenceDto> = ({ formState: { value } = {} }) => !value?.deathReportItems || value?.deathReportItems?.length === 0;
const hasDeathReport: ICondition<SurvivalAndRecurrenceDto> = ({ formState: { value } = {} }) => !!value?.deathReportItems && value?.deathReportItems?.length > 0;

const conditionSubscription = { value: true };

const SurvivalAndRecurrence: FunctionComponent<ISurvivalAndRecurrenceProps> = () => {
    const { classes } = useStyles();

    React.useEffect(() => {
        const survivalFupTable = document.getElementsByClassName("survivalFollowUp");

        if (survivalFupTable) {
            const survivalFupTableElement = survivalFupTable[0];

            if (survivalFupTableElement) {

                const rowLinks = survivalFupTableElement.querySelectorAll("a");

                let hasEvenFupNo = false;

                rowLinks.forEach(rowLink => {
                    const label = rowLink.querySelector(".MuiButton-label");

                    if (label) {
                        const survivalFupNo = +label.innerHTML.replace("Survival Follow Up", "").trim();

                        if (survivalFupNo % 2 === 0 && !hasEvenFupNo) {
                            hasEvenFupNo = true;
                        }
                    }
                });

                rowLinks.forEach(rowLink => {
                    const label = rowLink.querySelector(".MuiButton-label");

                    if (label) {
                        const survivalFupNo = +label.innerHTML.replace("Survival Follow Up", "").trim();

                        if (survivalFupNo % 2 === 1 && hasEvenFupNo) {
                            rowLink?.parentElement?.parentElement?.setAttribute("style", "background-color:#eee0ff");
                        } 
                    }
                });
            }
        }
    }, []);

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Survival Follow Up
                    </Typography>
                    <Condition
                        type={SurvivalAndRecurrenceDto}
                        condition={hasSurvivalFollowUp}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box className={`${classes.table} survivalFollowUp`}>
                            <GroupedSubformGrid
                                type={SurvivalFollowUpItem}
                                name="survivalFollowUpItems"
                                columns={survivalFollowUpColumns}
                                disabled
                                stickHeader
                                tableStyle={{
                                    height: '100%',

                                    '.MuiTableBody-root': {
                                        height: '100%',

                                        '& tr': {
                                            height: '100%',

                                            '& td': {
                                                padding: 0,
                                                height: '100%',

                                                '& *': {
                                                    height: '100%',
                                                }
                                            }
                                        }
                                    },
                                }}
                            />
                        </Box>
                    </Condition>
                    <Condition
                        type={SurvivalAndRecurrenceDto}
                        condition={hasNoSurvivalFollowUp}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Survival Follow Up"
                                description="There is no Survival Follow Up associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Breast Cancer Recurrence
                    </Typography>
                    <Condition
                        type={SurvivalAndRecurrenceDto}
                        condition={hasBreastCancerRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box className={classes.table}>
                            <GroupedSubformGrid
                                type={BreastCancerRecurrenceItem}
                                name="breastCancerRecurrenceItems"
                                columns={breastCancerRecurrenceColumns}
                                disabled
                                stickHeader
                            />
                        </Box>
                    </Condition>
                    <Condition
                        type={SurvivalAndRecurrenceDto}
                        condition={hasNoBreastCancerRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Breast Cancer Recurrence"
                                description="There is no Breast Cancer Recurrence associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Second Primary Malignancy
                    </Typography>
                    <Condition
                        type={SurvivalAndRecurrenceDto}
                        condition={hasSecondPrimaryMalignancy}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box className={classes.table}>
                            <GroupedSubformGrid
                                type={SecondPrimaryMalignancyItem}
                                name="secondPrimaryMalignancyItems"
                                columns={secondPrimaryMalignancyColumns}
                                disabled
                                stickHeader
                            />
                        </Box>
                    </Condition>
                    <Condition
                        type={SurvivalAndRecurrenceDto}
                        condition={hasNoSecondPrimaryMalignancy}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Second Primary Malignancy"
                                description="There is no Second Primary Malignancy associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Death Report Form
                    </Typography>
                    <Condition
                        type={SurvivalAndRecurrenceDto}
                        condition={hasDeathReport}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box className={classes.table}>
                            <GroupedSubformGrid
                                type={DeathReportItem}
                                name="deathReportItems"
                                columns={deathReportColumns}
                                disabled
                                stickHeader
                            />
                        </Box>
                    </Condition>
                    <Condition
                        type={SurvivalAndRecurrenceDto}
                        condition={hasNoDeathReport}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Death Report Form"
                                description="There is no Death Report Form associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default SurvivalAndRecurrence;
