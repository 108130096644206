import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Theme, Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, InputOnlyField, GroupedField, IUpdatedFormPropertyStyler } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, DateDisplay, FieldProvider, FormLabel } from '@ngt/forms';
import BiochemistryDisplay from '../../components/form/input/display/BiochemistryDisplay';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import { ConditionMode } from '@ngt/forms-core';
import { makeStyles } from 'tss-react/mui';

import { OrganFunction as OrganFunctionDto } from '../../apis/dtos';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';


interface IOrganFunctionProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const haemoglobinHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'haemoglobinUnits'
        }
    ]
};


const totalBilirubinHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'totalBilirubinUln'
        }
    ]
};


const aspartateTransaminaseHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'aspartateTransaminaseUln'
        }
    ]
};


const alanineTransaminaseHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'alanineTransaminaseUln'
        }
    ]
};


const alkalinePhosphataseHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'alkalinePhosphataseUln'
        }
    ]
};


const creatinineHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'creatinineUln'
        },
        {
            name: 'creatinineLln'
        }
    ]
};


const conditionSubscription = { value: true };

const hasLvef: ICondition<OrganFunctionDto> = ({ formState: { value } = {} }) => !!value?.mugaOrEcho;

const OrganFunction: FunctionComponent<IOrganFunctionProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Haematology
                    </Typography>
                    <GroupedField
                        name="haematologyDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        <strong>
                                            Test
                                        </strong>
                                    </Typography>
                                </TableCell>
                                <TableCell width="60%">
                                    <Typography>
                                        <strong>
                                            Value
                                        </strong>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="haemoglobin">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="haemoglobin"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        unitName="haemoglobinUnits"
                                        llnName="haemoglobinLln"
                                        ulnName="haemoglobinUln"
                                        eligibilityLlnName="haemoglobinEligibilityLln"
                                        eligibilityLlnInclusive={true}
                                        UpdatedFormPropertyStylerProps={haemoglobinHighlighting}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="rbcCount">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="rbcCount"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="rbcCountLln"
                                        ulnName="rbcCountUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> x 10<sup>12</sup>/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="totalNeutrophils">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="totalNeutrophils"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="totalNeutrophilsLln"
                                        ulnName="totalNeutrophilsUln"
                                        eligibilityLlnName="totalNeutrophilsEligibilityLln"
                                        eligibilityLlnInclusive={true}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> x 10<sup>9</sup>/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="platelets">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="platelets"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="plateletsLln"
                                        ulnName="plateletsUln"
                                        eligibilityLlnName="plateletsEligibilityLln"
                                        eligibilityLlnInclusive={true}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> x 10<sup>9</sup>/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="lymphocytes">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="lymphocytes"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="lymphocytesLln"
                                        ulnName="lymphocytesUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> x 10<sup>9</sup>/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="wbcCount">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="wbcCount"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="wbcCountLln"
                                        ulnName="wbcCountUln"
                                        eligibilityLlnName="wbcCountEligibilityLln"
                                        eligibilityLlnInclusive={true}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> x 10<sup>9</sup>/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Biochemistry
                    </Typography>
                    <GroupedField
                        name="biochemistryDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="gilbertSyndrome"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        <strong>
                                            Test
                                        </strong>
                                    </Typography>
                                </TableCell>
                                <TableCell width="60%">
                                    <Typography>
                                        <strong>
                                            Value
                                        </strong>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="totalBilirubin">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="totalBilirubin"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="totalBilirubinLln"
                                        ulnName="totalBilirubinUln"
                                        eligibilityUlnName="totalBilirubinEligibilityUln"
                                        eligibilityUlnInclusive={true}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> &micro;mol/L</Typography>
                                        }
                                        UpdatedFormPropertyStylerProps={totalBilirubinHighlighting}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="aspartateTransaminase">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="aspartateTransaminase"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="aspartateTransaminaseLln"
                                        ulnName="aspartateTransaminaseUln"
                                        eligibilityUlnName="astEligibilityUln"
                                        eligibilityUlnInclusive={true}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> IU/L</Typography>
                                        }
                                        UpdatedFormPropertyStylerProps={aspartateTransaminaseHighlighting}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="alanineTransaminase">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="alanineTransaminase"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="alanineTransaminaseLln"
                                        ulnName="alanineTransaminaseUln"
                                        eligibilityUlnName="altEligibilityUln"
                                        eligibilityUlnInclusive={true}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> IU/L</Typography>
                                        }
                                        UpdatedFormPropertyStylerProps={alanineTransaminaseHighlighting}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="alkalinePhosphatase">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="alkalinePhosphatase"
                                        component={BiochemistryDisplay}
                                        llnName="alkalinePhosphataseLln"
                                        ulnName="alkalinePhosphataseUln"
                                        decimalPlaces={2}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> IU/L</Typography>
                                        }
                                        UpdatedFormPropertyStylerProps={alkalinePhosphataseHighlighting}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="creatinine">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="creatinine"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        ulnName="creatinineUln"
                                        llnName="creatinineLln"
                                        eligibilityUlnName="creatinineEligibilityUln"
                                        eligibilityUlnInclusive={true}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> &micro;mol/L</Typography>
                                        }
                                        UpdatedFormPropertyStylerProps={creatinineHighlighting}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="calculatedCreatinineClearance">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="calculatedCreatinineClearance"
                                        component={BiochemistryDisplay}
                                        eligibilityLlnName="calculatedCreatinineEligibilityLln"
                                        decimalPlaces={2}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mL/min</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="calcium">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="calcium"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="calciumLln"
                                        ulnName="calciumUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="lactateDehydrogenase">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="lactateDehydrogenase"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="lactateDehydrogenaseLln"
                                        ulnName="lactateDehydrogenaseUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="totalProtein">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="totalProtein"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="totalProteinLln"
                                        ulnName="totalProteinUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> g/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="amylase">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="amylase"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="amylaseLln"
                                        ulnName="amylaseUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="lipase">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="lipase"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="lipaseLln"
                                        ulnName="lipaseUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> U/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="bloodUreaNitrogen">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodUreaNitrogen"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="bloodUreaNitrogenLln"
                                        ulnName="bloodUreaNitrogenUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mg/dL</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="serumUrea">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="serumUrea"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="serumUreaLln"
                                        ulnName="serumUreaUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mg/dL</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="potassium">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="potassium"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="potassiumLln"
                                        ulnName="potassiumUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="gammaGlutamylTransferase">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="gammaGlutamylTransferase"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="ggtLln"
                                        ulnName="ggtUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> IU/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="sodium">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="sodium"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="sodiumLln"
                                        ulnName="sodiumUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="uricAcid">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="uricAcid"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="uricAcidLln"
                                        ulnName="uricAcidUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mmol/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Thyroid Function
                    </Typography>
                    <GroupedField
                        name="thyroidFunctionDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        <strong>
                                            Test
                                        </strong>
                                    </Typography>
                                </TableCell>
                                <TableCell width="60%">
                                    <Typography>
                                        <strong>
                                            Value
                                        </strong>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="tsh">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="tsh"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="tshLln"
                                        ulnName="tshUln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mIU/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="t3">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="t3"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="t3Lln"
                                        ulnName="t3Uln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> mIU/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="t4">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="t4"
                                        component={BiochemistryDisplay}
                                        decimalPlaces={2}
                                        llnName="t4Lln"
                                        ulnName="t4Uln"
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> pmol/L</Typography>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        ECG
                    </Typography>
                    <GroupedField
                        name="ecg"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        LVEF Assessment
                    </Typography>
                    <GroupedField
                        name="mugaOrEcho"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Condition
                        type={OrganFunctionDto}
                        condition={hasLvef}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="mugaOrEchoValue"
                            component={BiochemistryDisplay}
                            decimalPlaces={2}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> %</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="mugaOrEchoAssessmentDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default OrganFunction;
